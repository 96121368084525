import React, { useState, useEffect } from "react";
import usdIcon from "../cryptoicons/svg/icon/usd.svg"; // Import USD icon statically

function Table({ finaldata }) {
    const [iconUrls, setIconUrls] = useState({});

    //const apiUrl = "http://localhost:3004/";
    const apiUrl = "https://ironglassinvestment.com:3004/"

    // Fetch the icon URL and store it in state
    async function getCryptoIconUrl(symbol) {
        let logo;
        if (symbol.toLowerCase() !== 'usd') {
            
            let icon = await fetch(`${apiUrl}crypto-icon?symbol=${symbol}`);
            icon = await icon.json();
            symbol = symbol.toUpperCase();
            logo = icon[symbol]?.logo || null;
        } else {
            logo = usdIcon; // Set the logo to the imported USD icon
        }
        return logo;
    }

    // Fetch and set icon URLs for each asset in finaldata
    useEffect(() => {
        async function fetchIcons() {
            const urls = {};
            for (let item of finaldata) {
                const iconUrl = await getCryptoIconUrl(item.Asset);
                urls[item.Asset] = iconUrl;
            }
            setIconUrls(urls);
        }

        if (finaldata) fetchIcons();
    }, [finaldata]);

    return (
        <div className="overflow-x-auto m-3 rounded-lg">
            <table className="min-w-full bg-[#1a1a1a] table-auto mb-8">
                <thead>
                    <tr className="bg-gray-800">
                        <th className="py-3 px-4 text-xs font-medium tracking-wider text-center text-white uppercase md:px-6">
                            Asset
                        </th>
                        <th className="py-3 px-4 text-xs font-medium tracking-wider text-center text-white uppercase md:px-6">
                            Holdings Distribution
                        </th>
                        <th className="py-3 px-2 text-xs font-medium tracking-wider text-center text-white uppercase md:px-4">
                            Balance
                        </th>
                        <th className="py-3 px-4 text-xs font-medium tracking-wider text-center text-white uppercase md:px-6">
                            Holdings Percentage
                        </th>
                        <th className="py-3 px-4 text-xs font-medium tracking-wider text-center text-white uppercase md:px-6">
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-slate-400/25 bg-almostblack">
                    {finaldata && finaldata.map((f, i) => (
                        <tr key={i}>
                            <td className="py-4 px-4 text-sm font-medium text-gray-500 text-center whitespace-nowrap md:px-6">
                                <div className="flex flex-col w-full text-center items-center">
                                    <img
                                        src={iconUrls[f.Asset] || ""}
                                        alt={`${f.Asset} Icon`}
                                        className="w-6 h-6 md:w-8 md:h-8"
                                    />
                                    {f.Asset}
                                </div>
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-500 text-center whitespace-nowrap md:px-6">
                                <div className="flex justify-center w-full">
                                <div className="bg-black rounded-full h-1.5 w-16 mt-1 md:w-20">
                                        <div
                                            className="bg-[#34b2fd] text-xs font-medium h-1"
                                            style={{ width: Math.round(f.Holdings) + '%' }}
                                        ></div>
                                    </div>
                                </div>
                            </td>
                            <td className="py-4 px-2 text-xs font-medium text-gray-500 text-center whitespace-nowrap md:px-4">
                                ${f.Balance.toFixed(2)}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-500 text-center whitespace-nowrap md:px-6">
                                <h5>{f.Holdings}%</h5>
                            </td>
                       
                            <td className="py-4 px-4 text-xs font-medium text-gray-500 text-center whitespace-nowrap md:px-6">
                                ${f.Price}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Table;



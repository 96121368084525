import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function InvestmentFAQ() {
  return (
    <div className="text-2xl">
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #34b2fd; /* Gold color */
            border: 1px solid #34b2fd; /* Gold border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #34b2fd; /* Gold color */
            border-bottom: 1px solid #34b2fd; /* Gold border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Light gray for content */
          }
          .MuiSvgIcon-root {
            color: #34b2fd; /* Gold color */
          }
        `}
      </style>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is Cryptocurrency Investment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Cryptocurrency investment involves buying, selling, and holding digital 
            assets such as Bitcoin, Ethereum, and other altcoins with the goal of 
            generating profits. At Ironglass Investment, we guide you through the 
            complexities of the crypto market to maximize your returns.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Is Cryptocurrency a Safe Investment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            While cryptocurrencies can offer high rewards, they are also highly 
            volatile and carry risks. Ironglass Investment employs advanced security 
            measures and strategies to protect your assets and minimize risk, helping 
            you make informed investment decisions.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>How Do You Manage My Crypto Portfolio?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our team analyzes market trends, uses cutting-edge tools, and applies 
            real-time data to strategically manage your cryptocurrency portfolio. 
            We adjust your assets dynamically to ensure optimal growth and mitigate 
            risks in a volatile market.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What Are the Benefits of Crypto Investments?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Cryptocurrency offers the potential for high returns, portfolio diversification, 
            and exposure to emerging technologies such as blockchain. Our experts at 
            Ironglass Investment ensure that your investment strategy aligns with your 
            financial goals and risk tolerance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>What Fees Do You Charge for Crypto Management?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We charge a competitive management fee based on the value of your crypto portfolio. 
            Our fees cover market analysis, asset management, and security measures to protect 
            and grow your investment. Contact us to learn more about our fee structure.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
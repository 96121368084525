import React from 'react';
import './FundPerformance.css'; // You will add your custom CSS here

const FundPerformance = () => {
    return (
        <div className="blockchain-performance">
            <h1>Fund Performance</h1>
            <p className="update-date">UPDATED: 2024-08-20</p>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>1 MONTH</th>
                        <th>3 MONTHS</th>
                        <th>YTD</th>
                        <th>1 YEAR</th>
                        <th>3 YEARS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Arbitrum</td>
                        <td className="negative">-12.28%</td>
                        <td className="negative">-17.40%</td>
                        <td className="positive">33.41%</td>
                        <td className="positive">122.89%</td>
                        <td className="positive">17.87%</td>
                    </tr>
                    <tr>
                        <td>Coinbase</td>
                        <td className="negative">-35.20%</td>
                        <td className="negative">-56.82%</td>
                        <td className="negative">-61.87%</td>
                        <td className="positive">109.34%</td>
                        <td className="positive">37.28%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default FundPerformance;

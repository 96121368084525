import React, { useState, useEffect, useCallback } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import schema from "./schema2";
import Candy from 'fusioncharts/themes/fusioncharts.theme.candy';

ReactFC.fcRoot(FusionCharts, TimeSeries, Candy);
const chart_props = {
  timeseriesDs: {
    type: "timeseries",
    renderAt: "container",
    width: "100%",
    height: "650",
    dataEmptyMessage: "Fetching data...",
    dataSource: {
      caption: { text: "Aum over time" },
      data: null,
      chart: {
        theme: "candy" //Apply theme
      },
      yAxis: [
        {
          plot: [
            {
              value: "totalAum",
              type: "area"
            }
          ]
        }
      ]
    }
  }
};

const Ex = ({historicalData}) => {
  const [ds, setds] = useState(chart_props);
  const [user, setUser] = useState(null) //Keeps track of current user
  const [uid, setUid] = useState('');//uid of currently logged in user
  const loadData = useCallback(async () => {
    //console.log(historicalData)
    try {
      const fusionTable = new FusionCharts.DataStore().createDataTable(
        historicalData,
        schema
      );
      const options = {...ds };
      options.timeseriesDs.dataSource.data = fusionTable;
      setds(options);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    console.log("render");
    loadData();
  }, [loadData]);

  return (
    <div style={{ width: '100%', height: 620  }}>
      <ReactFC {...ds.timeseriesDs} />
    </div>
  );
}

export default Ex;

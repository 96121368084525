import React from 'react';
//import './HeroSection.css';  // Make sure the path to your CSS file is correct

const BookNow= () => {
  // Replace with the actual phone number you want to dial
  const phoneNumber = '+5712889029';

  return (
    <a href="tel:5712719267">
      <button  className="neonButton">
      Contact Us
     </button>
    </a>
  );
};

export default BookNow;
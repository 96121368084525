const schema = [
    {
      name: "date",
      type: "date",
      format: "%-m/%-d/%Y %-I:%-M %p" 
    },
    {
      name: "totalAum",
      type: "number"
    }
  ];
  
  export default schema;
import React, {useState,useEffect,useContext} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from '../../images/navlogo2.png'
import {Link} from "react-router-dom";
import { Sling as Hamburger } from 'hamburger-react';
import LoginContext from '../../contexts/LoginContext';
import { initializeApp } from 'firebase/app';
import { getAuth , onAuthStateChanged} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {  doc, getDoc} from "firebase/firestore"; 


const Nav = () => {
  const {isOpen,setOpen} = useContext(LoginContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [uid, setUid] = useState('');

  const firebaseConfig = {
    apiKey: "AIzaSyCFA3Sa14Uokttej7rC7VFGSIePenYB6ac",
    projectId: "igi2-8ba54",
    storageBucket: "gs://igi2-8ba54.appspot.com",
  };

  async function userChange(){ 
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUid(uid)
        console.log(uid)
        // ...
      } else {
        // User is signed out
        console.log("none")
      }
    });
  }
  
  async function getCurrentProfile(){
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    console.log("uid is ", uid);
    const docSnap = await getDoc(doc(db, "users", uid));
    if (!docSnap.data) {
      console.log('No such document!');
    } else {
      console.log('Document data:', docSnap.data());
      const profile = docSnap.data();
      setSelectedImage(profile.img)
    }
  }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        userChange();
      }, []);
    useEffect(() => {
        getCurrentProfile();
    }, [uid]);
      



    return(
      
        <div className="navbar navbar-admin lg:justify-between">
          <div className="pr-0 w-screen justify-between lg:w-auto">
            {/* igi logo */}
            <Link to="/dashboard">
             <img src={logo} alt="..."className="w-28" />
            </Link>
          </div>
          {/* navbar row of buttons only on desktop */}
          <ul className="menu menu-horizontal p-0 hidden lg:flex">
            <li><Link to="/dashboard" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Portfolio</Link></li>
            <li><Link to="/calendly" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Analyst Meeting</Link></li>
            <li><Link to="/events" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Events</Link></li>
            {/* <li><Link to="/members" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Members</Link></li> */}
            {/* user profile settings and logout dropdown */}
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                <div className="w-10 rounded-full">
                  <img alt={logo} src={logo} />
                </div>
              </label>
              <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-[#030405] w-52">
                {/*<li><Link className="orbitron text-blue2 active:bg-black" to="/profile">Settings</Link></li>*/}
                <li><Link className="orbitron text-blue2 active:bg-black" to="/">Logout</Link></li>
              </ul>
            </div>
          </ul>
          <div className="lg:hidden text-blue2">
          <Hamburger toggled={isOpen} toggle={setOpen}/>
          </div>
            
        </div>

    )
}

export default Nav
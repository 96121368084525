import React, { useState, useEffect, useCallback } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import schema from "./schema";
import Candy from 'fusioncharts/themes/fusioncharts.theme.candy';

ReactFC.fcRoot(FusionCharts, TimeSeries, Candy);
const chart_props = {
  timeseriesDs: {
    type: "timeseries",
    renderAt: "container",
    width: "100%",
    height: "650",
    dataEmptyMessage: "Fetching data...",
    dataSource: {
      caption: { text: "Portfolio Growth over Time" },
      data: null,
      chart: {
        theme: "candy" //Apply theme
      },
      yAxis: [
        {
          plot: [
            {
              value: "totalUsdValue",
              type: "area"
            }
          ]
        }
      ]
    }
  }
};
//Firebase DB credentials

//Function to get current user from DB

const Ex = ({historicalData}) => {
  const [ds, setds] = useState(chart_props);
  const [user, setUser] = useState(null) //Keeps track of current user
  const [uid, setUid] = useState('');//uid of currently logged in user
  const loadData = useCallback(async () => {
    console.log(historicalData)
    try {
      const data2 =  [
        {date: "08/02/2023", Total: 455},
        {date: "08/03/2023", Total: 700},
        {date: "08/04/2023", Total: 1100},
        {date: "08/05/2023", Total: 4000},
        {date: "08/06/2023", Total: 10000},
        {date: "08/07/2023", Total: 15000},
        {date: "08/08/2023", Total: 11000},
        {date: "08/09/2023", Total: 45000},
        {date: "08/10/2023", Total: 40000},
        {date: "08/11/2023", Total: 100000},
        {date: "08/12/2023", Total: 120000},
        {date: "08/13/2023", Total: 160000},
        {date: "08/14/2023", Total: 220000},
        {date: "08/15/2023", Total: 250000},
        {date: "08/16/2023", Total: 230000},
        {date: "08/17/2023", Total: 270000},
        {date: "08/18/2023", Total: 320000},
        {date: "08/19/2023", Total: 400000},
        {date: "08/20/2023", Total: 520000},
        {date: "08/21/2023", Total: 600000},
        {date: "08/22/2023", Total: 800000},
        {date: "08/23/2023", Total: 1200000},
        {date: "08/24/2023", Total: 1500000},
      ];
      //console.log("data2", data2)
      const fusionTable = new FusionCharts.DataStore().createDataTable(
        historicalData,
        schema
      );
      const options = {...ds };
      options.timeseriesDs.dataSource.data = fusionTable;
      setds(options);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    console.log("render");
    loadData();
  }, [loadData]);

  return (
    <div style={{ width: '100%', height: 620  }}>
      <ReactFC {...ds.timeseriesDs} />
    </div>
  );
}

export default Ex;

import React,{useContext,useEffect} from 'react';
import Myparticle from './Particless.js';
import LoginContext from '../contexts/LoginContext';
import Login from './Login.js';
import Signup from './Signup';
import LearnMore from './LearnMore';
import AuthNav from './navbars/NavbarAuth';

function Main() {
  return (
    <div className='mainpage'>
      <Myparticle/>
      <AuthNav/>
      <div className='section'>
          <div className='text flex flex-col content-between'>
            <div className='flex flex-row text-center place-content-center lg:place-content-start min-w-full md:p-9'>
              <p data-aos="fade-right" data-aos-duration="2300" className="glitch"><span aria-hidden="true"></span>BYTE THE GLASS</p>
            </div>
            <div className='flex flex-row min-w-full text-center place-content-center lg:place-content-end md:p-9'>
              <p data-aos="fade-right" data-aos-delay="350" data-aos-duration="2500" className="glitch"><span aria-hidden="true"></span>BUILD THE FUTURE</p>
            </div>
          </div> 
      </div>
    </div>
  );
}
  
export default Main;
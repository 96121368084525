import React,{useState, useEffect} from 'react';
import DownloadSection from './Documents';


//login card component
function Signup() {

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)

    async function submitInfo (e){
      e.preventDefault()

    }

    const onChangeHandler = (field, val)=> {
      if(field === "name"){
        setName(val);
      }
      if(field === "email"){
        setEmail(val);
      }
    }

    useEffect(()=>{

    },[error])

    

    return (
      <div className="login flex flex-col content-center">
        <DownloadSection/>
        <div className="card glass mb-8 w-96">
          <form>
            <div className="card-body text-blue2">
              <h1 className="card-title">Signup</h1>
              <input type="name" placeholder="Name" id="name" className="text-slate-500 mt-2 input w-full max-w-xs" onChange={(e) => {onChangeHandler("name",e.target.value)}}/>
              <input type="email" placeholder="Email" id="email" className="text-slate-500 mt-4 input w-full max-w-xs" onChange={(e) => {onChangeHandler("email",e.target.value)}}/>

              <button onClick={submitInfo } type="submit" className="group relative flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm mt-3 font-medium text-white">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg class="h-5 w-5 text-gold3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                  </svg>
                </span>
                Signup
              </button>
              {/* if error show error in red*/}
              {error && <p className='danger'>{error}</p>}
            </div>
          </form>
        </div> 
      </div>
    );
}

export default Signup;
import Investment from "../images/navlogo.png";
import investmentVideo from "../images/IGI_video.mp4";
import Particless from "./Particless";
import NavbarAuth from "./navbars/NavbarAuth";
import InvestmentFAQ from "./Investment_FAQ.js";
import FundPerformance from "./FundPerformance";
import ContactButton from "./ContactButton"

const LearnMore = () => {
  return (
    <div className="section">
      <Particless />
      <NavbarAuth />
      <div className="p-2 lg:p-12 m-2 lg:m-5 text-gray-400 mb-4 lg:mb-10">
        <h1 className="text-4xl lg:text-6xl text-[#34b2fd] p-2 lg:p-4 m-2 font-bold">
          Ironglass Investment
        </h1>
        <div className="flex flex-col lg:flex-row">
          <p className="text-xl lg:text-2xl p-2 lg:p-4 m-2 card border border-[#34b2fd] rounded-xl bg-black">
            Ironglass Investment offers expert management of cryptocurrency portfolios. 
            Our comprehensive services are designed to help you navigate the rapidly 
            evolving world of digital assets. We provide tailored strategies for crypto 
            investment, security, and long-term wealth growth. From market insights to 
            real-time data analysis, Ironglass Investment ensures that your crypto 
            assets are positioned for success in an ever-changing financial landscape.
          </p>
          <img
            className="w-full lg:w-[50%] rounded-lg p-2 lg:p-5 z-10"
            src={Investment}
            alt="Ironglass Investment Logo"
          />
        </div>
      </div>

      <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
        <div className="card bg-black p-2 ">
          <video
            className="w-full h-full lg:h-auto"
            src={investmentVideo}
            autoPlay
            muted
            loop
            playsInline
          />
        </div>

        <div className="w-full lg:w-[100%]">
          <p className="text-xl lg:text-2xl card border border-[#34b2fd] rounded-xl bg-black p-2 lg:p-4 m-2">
            At Ironglass Investment, our mission is to help you unlock the full potential 
            of cryptocurrency. We understand the complexities of the market and employ 
            cutting-edge technologies to secure your digital assets. Whether you're new 
            to crypto or looking to optimize your investments, our team is here to guide 
            you through every step, ensuring maximum growth while mitigating risks.
          </p>
        </div>
      </div>

      {/* place IGI graph here */}

      {/* <FundPerformance /> */}

      {/* <Testimonials /> */}

      <div className="">
        <h1 className="text-2xl lg:text-4xl text-[#34b2fd] font-bold flex justify-center p-4">
          Frequently Asked Questions by Our Clients
        </h1>
        <InvestmentFAQ />
      </div>

      <div className="mb-[-2rem]">
        <h1 className="text-2xl lg:text-4xl text-[#34b2fd] font-bold flex justify-center p-4">
          Contact us to manage your Crypto Investments securely!
        </h1>
        <div className="button-section">
            <ContactButton />
        </div>
        <br/>
        <br/>
        <br/>
       
      </div>
    </div>
  );
};

export default LearnMore;